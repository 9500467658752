<template>
  <div class="weather-insight">
    <!-- <Banner v-bind:bannerInfo="bannerInfo"></Banner> -->
    <div class="banner"><img src="@assets/img/weatherinsight/weatherinsight_banner.png" alt=""></div>
    <ScenesPresentation></ScenesPresentation>
  </div>
</template>

<script>
  // import Banner from '@components/content/Banner.vue'
  import ScenesPresentation from './childComps/ScenesPresentatiion.vue'

  export default {
    name: 'WeatherInsight',
    components: {
      ScenesPresentation
    },
    data() {
      return {
        bannerInfo: [
          {imgUri: require("@assets/img/weatherinsight/weatherinsight_banner.png"), buttonFlag: false}
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .weather-insight {
    width: 100%;
    .banner {
      width: 100%;
      // height: 800px;
      img {
        display: block;
        width: 100%;
      }
    }
  }
</style>