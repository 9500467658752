<template>
  <div class="scenes-presentation-wrap">
    <div class="scenes-presentation">
      <div class="tab-wrap">
        <el-tabs :tab-position="'left'" style="height: 470px;" class="el-tabs" type="border-card">
          <el-tab-pane class="el-tab-pane"  v-for="(item, index) in tabItemInfo" :key="index">
            <span slot="label"><i></i>{{item.title}}</span>
            <div class="el-tab-pane-title">{{item.title}}</div>
            <p>{{item.content}}</p>
            <!-- <a :href="item.link">{{item.linkLabel}}</a> -->
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@components/common/Footer.vue'

export default {
  name: 'ScenesPresentation',
  components: {
    Footer
  },
  data() {
    return {
      tabItemInfo: [
        {title: '健康医疗', content: '基于数值模式、地理信息、流行病学模型等多种技术，构建综合健康模型，进行健康影响预', link: "#", linkLabel: "敬请期待"},
        {title: '双碳达标', content: '为新能源发电企业风电、光电进行新能源电力评估和高频率短时风光监测预报（高频功率预'},
        {title: '智能驾驶', content: '气象信息在公路旅行的安全性和畅通方面起着重要作用。准确的天气预报和实时情报对于保障道路通畅和交通运输至关重要。我们提供天气变化对规划路线的影响，帮助您在遇到天气事件时做出明智决定。', link: "#", linkLabel: "敬请期待"},
        {title: '移动互联', content: '移动互联开展校园气象科普课程，构建系统气象科普模式，通过气象科普活动、研学实践活动等将学等等', link: "#", linkLabel: "敬请期待"},
        {title: '通信运营', content: '开展校园气象科普课程，构建系统气象科普模式，通过气象科普活动、研学实践活动等将学', link: "#", linkLabel: "敬请期待"},
        {title: '智慧农业', content: '为农业生产者和农业生产企业准确快速地提供本地化的农业情报和预报信息，为农业运营决', link: "#", linkLabel: "敬请期待"},
      ]
    }
  },
  created() {
    console.log('天气洞察 created');
  }
}
</script>

<style lang="scss" scoped>
  .scenes-presentation-wrap {
    background-color: #1a2839;
    overflow: hidden;
    .scenes-presentation {
      width: 100%;
      height: 738px;
      background-color: rgba(209, 219, 246, .9);
      background-image: url('~@assets/img/weatherinsight/weatherinsight_bg.png');
      .tab-wrap {
        width: 1206px;
        height: 470px;
        margin: 0 auto;
        padding-top: 120px;
        ::v-deep .el-tabs {
          width: 735px;
          background: transparent;
          border: none;
          box-shadow: none;
          .el-tabs__header {
            width: 247px;
            padding-top: 50px;
            padding-bottom: 50px;
            background-color: #2e436f;
            border-radius: 10px;
            box-shadow: 0 2px 5px 2px rgba(0 0 0 / 20%);
            border-right: none;
            .el-tabs__nav-wrap {
              .el-tabs__nav-scroll {
                .el-tabs__nav {
                  .el-tabs__item {
                    position: relative;
                    text-align: center;
                    height: 46px;
                    line-height: 46px;
                    margin-bottom: 10px;
                    border: none;
                    span {
                      font-size: 18px;
                      color: #e0e0e0;
                      margin-left: 45px;
                      i {
                        position: absolute;
                        top: 7px;
                        left: 50px;
                        width: 33px;
                        height: 33px;
                        background-size: 100% 100%;
                      }
                    }
                    &:nth-of-type(1) {
                      i {
                        background-image: url('~@assets/img/weatherinsight/icon_jiankangyiliao_white.png');
                      }
                    }
                    &:nth-of-type(2) {
                      i {
                        background-image: url('~@assets/img/weatherinsight/icon_shuangtandabiao_white.png');
                      }
                    }
                    &:nth-of-type(3) {
                      i {
                        background-image: url('~@assets/img/weatherinsight/icon_zhinengjiashi_white.png');
                      }
                    }
                    &:nth-of-type(4) {
                      i {
                        background-image: url('~@assets/img/weatherinsight/icon_yidonghulian_white.png');
                      }
                    }
                    &:nth-of-type(5) {
                      i {
                        background-image: url('~@assets/img/weatherinsight/icon_tongxinyunwei_white.png');
                      }
                    }
                    &:nth-of-type(6) {
                      i {
                        background-image: url('~@assets/img/weatherinsight/icon_zhihuinongye_white.png');
                      }
                    }
                    &:hover {
                      background-color: transparent;
                      background-image: url('~@assets/img/weatherinsight/item_isactive_bg.png');
                    }
                  }
                  .is-active {
                    background-color: transparent;
                    background-image: url('~@assets/img/weatherinsight/item_isactive_bg.png');
                    background-size: 100% 100%;
                    span {
                      color: #ffffff;
                      font-size: 20px;
                      letter-spacing: 3px;
                      i {
                        left: 45px;
                      }
                    }
                    &:nth-of-type(1) {
                      i {
                        background-image: url('~@assets/img/weatherinsight/icon_jiankangyiliao_blue.png');
                      }
                    }
                    &:nth-of-type(2) {
                      i {
                        background-image: url('~@assets/img/weatherinsight/icon_shuangtandabiao_blue.png');
                      }
                    }
                    &:nth-of-type(3) {
                      i {
                        background-image: url('~@assets/img/weatherinsight/icon_zhinengjiashi_blue.png');
                      }
                    }
                    &:nth-of-type(4) {
                      i {
                        background-image: url('~@assets/img/weatherinsight/icon_yidonghulian_blue.png');
                      }
                    }
                    &:nth-of-type(5) {
                      i {
                        background-image: url('~@assets/img/weatherinsight/icon_tongxinyunwei_blue.png');
                      }
                    }
                    &:nth-of-type(6) {
                      i {
                        background-image: url('~@assets/img/weatherinsight/icon_zhihuinongye_blue.png');
                      }
                    }
                  }
                }
              }
            }
          }
          .el-tabs__content {
            padding: 40px 50px;
            .el-tab-pane {
              .el-tab-pane-title {
                height: 30px;
                line-height: 30px;
                padding-left: 15px;
                color: #3139c4;
                font-size: 20px;
                font-weight: 700;
                letter-spacing: 4px;
                border-left: 5px solid #3139c4;
                margin-bottom: 70px;
              }
              p {
                color: #545466;
                line-height: 26px;
                text-indent: 30px;
              }
              a {
                width: 245px;
                height: 52px;
              }
            }
          }
        }
      }
    }
  }
</style>
